<template>
	<div class="list-tool-bar margin-t3">
		<el-button-group class="action">
			<el-button v-if="activeName === 1" @click="emit('onCreate')" type="primary">{{ t('table.create') }}</el-button>
			<el-button type="primary" plain @click="emit('onExport')">{{ t('table.export') }}</el-button>
		</el-button-group>
		<div class="search">
			<!-- 扫码管理用户显示 -->
			<div class="search_item" v-if="store.state.users.tags === 1">
				<el-input
          clearable
          v-model="searchData.filter_scan_mail"
          :placeholder="t('assign.product_user_mail')" @input="doSearch" />
			</div>
			<div class="search_item">
				<el-select
          v-model="searchData.filter_project_id"
          :placeholder="t('dashboard.project')"
          @change="doSearch"
          filterable
          clearable>
					<el-option v-for="(item, index) in projectList" :key="item.id" :label="item.name" :value="item.id">
					</el-option>
				</el-select>
			</div>
			<div v-if="activeName !== 1" class="search_item">
				<el-select
          v-model="searchData.filter_source"
          :placeholder="t('assign.application_source')"
          @change="doSearch"
          filterable
          clearable>
					<el-option v-for="(item, index) in sourceList" :key="item.id" :label="item.name" :value="item.id">
					</el-option>
				</el-select>
			</div>
			<div class="search_item">
				<el-select
          v-model="searchData.filter_product_id"
          @change="doSearch"
          :placeholder="t('product.product_name')"
          filterable
          clearable>
					<el-option v-for="(item, index) in productList" :key="index" :label="item.label" :value="item.value">
					</el-option>
				</el-select>
			</div>
			<div class="search_item search_time">
				<div class="time_select">
					<el-select v-model="dateType">
						<el-option :label="t('table.creation_time')" value="1" />
					</el-select>
				</div>
				<!-- 时间选择框 -->
				<custem-time ref="timeRef" @setTime="setTime" />
			</div>
			<div v-if="activeName !== 1" class="search_item">
				<el-select
          v-model="searchData.filter_status"
          @change="doSearch"
          :placeholder="t('enterprise.review_status')"
          filterable
          clearable>
					<el-option v-for="(item, index) in statusList" :key="index" :label="item.name" :value="item.id">
					</el-option>
				</el-select>
			</div>
      <!-- 订单类别 -->
			<div v-if="activeName === 1" class="search_item">
				<el-select
          v-model="searchData.filter_order_category_id"
          filterable
          clearable
          @change="doSearch"
					:placeholder="t('order.order_type')">
					<el-option v-for="(item, index) in orderTypeList" :key="index" :label="item.name" :value="item.id">
					</el-option>
				</el-select>
			</div>
		</div>
	</div>
</template>
<script setup>
import { ref, onMounted, inject, watch } from 'vue'
import { productManagement, AuthManagement } from '@/utils/api'
import store from '@/store'

const t = inject('t')
const emit = defineEmits(['getList', 'onExport', 'onCreate'])
const productList = ref([])
const projectList = ref([])
const dateType = ref('1') // 搜索时间类型
const searchData = ref({
	page: 1
})
const timeRef = ref()
const props = defineProps({
  activeName: {
    type: Number,
    default: 1
  }
})
watch(() => props.activeName, val => {
  if (val && timeRef.value) {
    timeRef.value.clearTime()
  }
}, { deep: true })

// 订单类型
const orderTypeList = ref([
	{
		id: 1001,
		name: t('order.type_1001')
	},
	{
		id: 1002,
		name: t('order.type_1002')
	},
	{
		id: 1003,
		name: t('order.type_1003')
	},
])
const statusList = ref([
	{ // 待分配
		name: t('assign.status_0'),
		id: '0'
	},
	{ // 已分配
		name: t('assign.status_1'),
		id: '1'
	},
	{ // 已撤销
		name: t('assign.status_8'),
		id: '8'
	},
	{ // 已拒绝
		name: t('assign.status_9'),
		id: '9'
	}
])

const sourceList = ref([
	{ // 二维码扫码
		name: t('assign.scan_code'),
		id: '0'
	},
	{ // 子用户申请
		name: t('assign.sub_user_apply'),
		id: '1'
	},
	{ // 主用户创建
		name: t('assign.admin_create'),
		id: '2'
	},
	{ // 第三方平台
		name: t('assign.third_platform'),
		id: '3'
	}
])

const setTime = (timeDate) => {
	if (timeDate.length) {
		searchData.value.filter_created_at = timeDate[0]
		searchData.value.filter_end_at = timeDate[1]
	}
	doSearch()
}

// 获取项目列表
const getOrderList = () => {
	AuthManagement.ProjectList({
		is_paginate: false
	}).then(res => {
		projectList.value = res.items;
	})
}

// 获取企业产品列表
const getProductList = () => {
	productManagement.EnterpriseProduct({
		is_paginate: false,
		filter_enterprise_id: store.state.users.enterprise_id
	}).then(res => {
		let arr = []
		res.items.forEach(ele => {
			arr.push({
				label: `${ele.product.deployment.name}/${ele.product.category.name}/${ele.product.name}`,
				value: ele.product.id
			})
		});
		productList.value = arr;
	})
}

onMounted(() => {
	getProductList()
	getOrderList()
})

// 搜索事件
const doSearch = () => {
	for (let key in searchData.value) {
		if (!!!searchData.value[key])
			delete searchData.value[key]
	}
	emit('getList', searchData.value)
}

defineExpose({
  searchData
})
</script>
